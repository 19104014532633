import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro"; //eslint-disable-line

const ModalContent = tw.div`relative transform overflow-hidden rounded-lg bg-black bg-opacity-50 shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg border-4 border-purple-700`;


export default function({open, setOpen}) {

    const Modal = styled.div(open ? tw`flex items-center justify-center fixed inset-0 bg-black bg-opacity-75 overflow-y-auto h-full w-full z-30` : tw`fixed inset-0 hidden`)

    return (
        <Modal>
            <ModalContent>
                <div tw="px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div>
                        <div tw="mt-3 md:text-center text-left">
                            <h3 tw="text-3xl sm:text-4xl lg:text-5xl text-white" id="modal-title">Muito obrigado!</h3>
                            <div tw="mt-5">
                                <p tw="text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100">Aproveite para nos seguir no Instagram!</p>
                            </div>
                            <div tw="mt-5 md:mt-10 min-h-128 w-2/3 w-full">
                                <iframe tw="rounded-lg border-2 mt-2 min-h-128 w-full" src="https://www.instagram.com/gerapost.ai/embed/"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div tw="px-4 py-3 flex justify-center sm:px-6">
                    <button type="button"
                            onClick={() => setOpen(false)}
                            tw="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-100 sm:mt-0 sm:w-auto">Fechar
                    </button>
                </div>
            </ModalContent>
        </Modal>
    )
}
