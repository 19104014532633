import React, {useState} from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/BackgroundAsImageWithCenteredContent.js";
import Features from "components/features/VerticalWithAlternateImageAndText.js";
import ContactUsForm from "components/forms/SimpleContactUs.js";
import Footer from "components/footers/FiveColumnWithBackground.js";
import RegisteredModal from "../components/modals/RegisteredModal";

export default () => {

    const [open, setOpen] = useState(false)

    return (
        <>
            <RegisteredModal open={open} setOpen={setOpen}/>
            <AnimationRevealPage disabled>
                <Hero setOpen={setOpen}/>
                <Features/>
                <ContactUsForm setOpen={setOpen}/>

                <Footer/>
            </AnimationRevealPage>
        </>
    )
}
