import React, {useRef} from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import background from '../../images/landing-bg.jpg'

import downArrow from '../../images/angel-down-circle.svg'

import Header, { NavLink, NavLinks, PrimaryLink as PrimaryLinkBase, LogoLink, NavToggle, DesktopNavLinks } from "../headers/light.js";
import SimpleContactUs from "../forms/SimpleContactUs";
import SimpleSubscribeNewsletter from "../forms/SimpleSubscribeNewsletter";
import TwoColContactUsWithIllustration from "../forms/TwoColContactUsWithIllustration";

const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none w-full`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;

const PrimaryLink = tw(PrimaryLinkBase)`rounded-full`
const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover h-screen min-h-144`}
  background-image: url(${background})
`;

const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-black opacity-75`;

const HeroContainer = tw.div`z-20 relative px-6 sm:px-8 mx-auto h-full flex flex-col`;
const Content = tw.div`px-4 flex flex-1 flex-col justify-center items-center`;

const DownArrow = styled.div`
  ${tw`invisible xs:visible h-10 md:h-20 w-10 md:w-20 bg-cover absolute bottom-0 mb-10 md:mb-20 transition ease-in-out duration-100 hover:-translate-y-5`}
  background-image: url(${downArrow})
`;



export default ({setOpen}) => {
  const bottomRef = useRef(null);

  function goToTop() {
    bottomRef?.current?.scrollIntoView({ behavior: 'smooth' });
  }

  const navLinks = [
    // <NavLinks key={1}>
    //   <NavLink href="#">
    //     About
    //   </NavLink>
    //   <NavLink href="#">
    //     Blog
    //   </NavLink>
    //   <NavLink href="#">
    //     Locations
    //   </NavLink>
    //   <NavLink href="#">
    //     Pricing
    //   </NavLink>
    // </NavLinks>,
    // <NavLinks key={2}>
    //   <PrimaryLink href="/#">
    //     Quero participar!
    //   </PrimaryLink>
    // </NavLinks>
  ];

  return (
    <>
      <Container>
        <OpacityOverlay />
        <HeroContainer>
          <StyledHeader links={navLinks} />
          <Content>
            <TwoColContactUsWithIllustration setOpen={setOpen}/>
            <DownArrow onClick={goToTop}/>
          </Content>
        </HeroContainer>
      </Container>
      <div ref={bottomRef}/>
    </>
  );
};
