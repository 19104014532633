import React, {useState} from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import EmailIllustrationSrc from "images/email-illustration.svg";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(SectionHeading)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col lg:flex-row`
const Input = tw.input`border-2 px-5 py-3 rounded focus:outline-none font-medium transition duration-300 hocus:border-primary-500`

const SubmitButton = tw(PrimaryButtonBase)`inline-block lg:ml-6 mt-6 lg:mt-0`

export default ({
  subheading = undefined,
  heading = <>
    <span tw="text-gray-100">
      Libere o potencial de suas redes sociais com nossa </span>
    <span tw="text-primary-600">Inteligência Artificial</span></>,
  description = <>
    <span tw="text-gray-100">gerapost.ai </span>
    é uma ferramenta de inteligência artificial que permite a
    geração automática de conteúdo para as redes sociais.
    Em breve, entraremos em um período de testes gratuito.
  </>,
  cadastre = "Cadastre seu email para receber um convite para testar a ferramenta assim que ela estiver disponível.",
  submitButtonText = "Cadastrar",
  textOnLeft = true, setOpen
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  const [loading, setLoading] = useState(false)
  async function registerEmail(e) {
    e.preventDefault();

    setLoading(true)

    try {
      const url = "https://landing-api.gerapost.ai/api/v1/register"
      const email = e.target.email.value
      const result = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams({
          'email': email,
        })
      });

      if(result.ok) {
        setOpen(true)
      }

    } catch (e) {
      console.error(e)
    }
    finally {
      setLoading(false)
    }
  }

  return (
    <Container>
      <TwoColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            <Description>{description}</Description>
            <Description>{cadastre}</Description>
            <Form onSubmit={registerEmail}>
              <Input type="email" name="email" placeholder="Seu endereço de email" />
              <SubmitButton disabled={loading} type="submit">{submitButtonText}</SubmitButton>
            </Form>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
